@import '../../variables.scss';

.posts {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
}

.tag-filter {
    margin-bottom: 20px;
    
    .tag-label {
        font-weight: bold;
        margin-right: 10px;
    }
    
    .tag-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: 0;
        
        li {
            margin: 5px;
            padding: 4px 10px;
            background-color: #f0f0f0;
            border-radius: 20px;
            cursor: pointer;
            transition: all 0.2s ease;
            font-size: 0.9rem;
            font-weight: bold;
            
            &:hover {
                background-color: #e0e0e0;
            }
            
            &.active {
                background-color: #ff6600;
                color: white;
            }
        }
    }
}

@media (max-width: 600px) {
    .posts {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    
    .tag-filter {
        .tag-list {
            flex-direction: column;
            
            li {
                margin: 3px 0;
            }
        }
    }
}
